import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import ButtonMore from '../shared/btnMore'
import TrackVisibility from 'react-on-screen'
import ExperienceListItem from './experienceListItem'

const Heading = styled.h1`
  text-transform: uppercase;
  margin: 100px 0 20px 0;
  border-bottom: 2px solid ${props => props.theme.yellow};
  color: ${props => props.theme.yellow};
  padding: 10px 0;
`
const PageContent = styled.div`
  padding-right: 60px;
  .experience.hide {
    transition: all 0.5s ease-in-out;
    max-height: ${props => (props.showMore ? '500px' : '0')};
    overflow: hidden;
    display: ${props => (props.showMore ? 'grid' : 'none')};
  }
  @media (max-width: 600px) {
    padding-right: 30px;
  }
`

class AboutPageContainer extends Component {
  state = {
    showMore: false,
  }

  showMoreHandler = () => {
    const { showMore } = this.state
    this.setState({
      showMore: !showMore,
    })
    // Force TrackVisibility to kick-in
    window.scrollBy(0, 1)
  }

  render() {
    const { showMore } = this.state
    return (
      <StaticQuery
        query={ABOUT_EXPERIENCE_CONTENT}
        render={({ wordpressPage }) => (
          <PageContent showMore={showMore}>
            <Heading>Experience</Heading>
            {wordpressPage.acf.experience.map((experience, i) => (
              <TrackVisibility
                partialVisibility
                key={experience.name}
                className={`experience ${i < 4 ? 'show' : 'hide'}`}
              >
                <ExperienceListItem data={experience} />
              </TrackVisibility>
            ))}
            <ButtonMore
              text={showMore ? 'Show Less' : 'Load More'}
              action={this.showMoreHandler}
              showMore={showMore}
            />
          </PageContent>
        )}
      />
    )
  }
}

const ABOUT_EXPERIENCE_CONTENT = graphql`
  query AboutexperiencePageDetails {
    wordpressPage(wordpress_id: { eq: 56 }) {
      acf {
        experience {
          name
          title
          period
          content
          logo {
            source_url
          }
        }
      }
    }
  }
`

export default AboutPageContainer
