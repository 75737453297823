import React from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import CheckBullet from '../../images/icon-check-circle.svg'

const ItemStyles = styled.div`
  .courses {
    background: #fff;
    padding: 30px;
    margin: 0 0 40px 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-columns: 160px 1fr;
    img {
      width: 110px;
      height: 110px;
      margin: 0;
    }
    h2 {
      text-transform: uppercase;
      margin-bottom: 8px;
      letter-spacing: 1px;
      font-weight: 400;
      color: ${props => props.theme.dark};
    }
    p {
      color: ${props => props.theme.dark};
      margin: 5px 0;
    }
    .period {
      color: rgba(0, 0, 0, 0.4);
    }
    .div {
      width: 80px;
      height: 4px;
      background-color: #eee;
      margin: 10px 0 0 0;
    }
    .content {
      color: ${props => props.theme.dark};
      p {
        font-size: 14px;
        background-image: url(${props => props.bullet});
        background-repeat: no-repeat;
        background-position: 0px 3px;
        padding: 3px 0 3px 30px;
        line-height: 18px;
        margin: 10px 0;
      }
    }
  }
  @media (max-width: 1024px) {
    .courses {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }
`

const ItemAnimation = posed.div({
  open: { y: 0, opacity: 1 },
  closed: { y: -50, opacity: 0 },
})

const CoursesListItem = ({ data, isVisible }) => (
  <ItemAnimation pose={isVisible ? 'open' : 'closed'}>
    <ItemStyles bullet={CheckBullet}>
      <div className="courses">
        {data.logo && <img src={data.logo.source_url} alt={data.name} />}
        <div>
          <h2 dangerouslySetInnerHTML={{ __html: data.name }} />
          <p
            className="period"
            dangerouslySetInnerHTML={{
              __html: data.period,
            }}
          />
          <div className="div" />
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: data.courses }}
          />
        </div>
      </div>
    </ItemStyles>
  </ItemAnimation>
)
export default CoursesListItem
