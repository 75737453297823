import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Header from '../components/header/header'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageDetailedHeader from '../components/shared/pageDetailedHeader'
import AboutPageContainer from '../components/about/aboutPageContainer'
import ExperiencePageContainer from '../components/about/experiencePageContainer'
import EducationPageContainer from '../components/about/educationPageContainer'
import CoursesPageContainer from '../components/about/coursesPageContainer'
import ToolsPageContainer from '../components/about/toolsPageContainer'
import { Element } from 'react-scroll'
import AboutNav from '../components/about/aboutNav'
import styled from 'styled-components'

const AboutPageContainerWrapper = styled.div`
  background-image: linear-gradient(-180deg, #5388bb 5%, #245c92 100%);
  padding: 60px 20px 150px 20px;
  @media (max-width: 1024px) {
    padding: 60px 0 150px 0;
  }
`
const AboutPageContainerInner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 60px;
  @media (max-width: 1024px) {
    grid-template-columns: 210px 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-left: 60px;
  }
  @media (max-width: 600px) {
    margin-left: 30px;
  }
`
const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      headerBG: file(relativePath: { eq: "page-header-about.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="About" keywords={[`design`, `developer`, `react`]} />
      <Header colour="#F5D123" />
      <PageDetailedHeader
        bg={data.headerBG}
        intro="I'm an all-hands-on-deck product interface designer. I thrive when I have the opportunity to create something that is simple, beautiful & easy to use. "
      />
      <AboutPageContainerWrapper>
        <AboutPageContainerInner>
          <AboutNav />
          <div className="page-inner">
            <Element name="about">
              <AboutPageContainer />
            </Element>
            <Element name="experience">
              <ExperiencePageContainer />
            </Element>
            <Element name="education">
              <EducationPageContainer />
            </Element>
            <Element name="courses">
              <CoursesPageContainer />
            </Element>
          </div>
        </AboutPageContainerInner>
      </AboutPageContainerWrapper>
    </Layout>
  )
}
export default AboutPage
