import React from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import ArrowBullet from '../../images/icon-arrow-right.svg'

const ItemStyles = styled.div`
  background: #fff;
  padding: 30px;
  margin: 0 0 40px 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 160px 1fr;
  img {
    width: 110px;
    height: 110px;
    margin: 0;
  }
  h2 {
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 1px;
    font-weight: 400;
    color: ${props => props.theme.dark};
  }
  p {
    color: ${props => props.theme.dark};
    margin: 0;
  }
  .period {
    color: rgba(0, 0, 0, 0.4);
    text-align: right;
  }
  .title-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .div {
    width: 80px;
    height: 4px;
    background-color: #eee;
    margin: 10px 0 25px 0;
  }
  .content {
    color: ${props => props.theme.dark};
    p {
      font-size: 14px;
      background-image: url(${props => props.bullet});
      background-repeat: no-repeat;
      background-position: 0px 2px;
      padding: 0 0 0 30px;
      line-height: 18px;
      margin: 10px 0;
    }
  }
  transition: all 0.5s ease-in-out;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  @media (max-width: 900px) {
    .title-row {
      grid-template-columns: 1fr;
      justify-items: flex-start;
    }
  }
`

const ItemAnimation = posed.div({
  open: { y: 0, opacity: 1 },
  closed: { y: -50, opacity: 0 },
})

const ExperienceListItem = ({ data, isVisible }) => (
  <ItemAnimation pose={isVisible ? 'open' : 'closed'}>
    <ItemStyles bullet={ArrowBullet}>
      <img src={data.logo.source_url} alt={data.name} />
      <div>
        <h2 dangerouslySetInnerHTML={{ __html: data.name }} />
        <div className="title-row">
          <p
            className="title"
            dangerouslySetInnerHTML={{
              __html: data.title,
            }}
          />
          <p
            className="period"
            dangerouslySetInnerHTML={{
              __html: data.period,
            }}
          />
        </div>
        <div className="div" />
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
    </ItemStyles>
  </ItemAnimation>
)
export default ExperienceListItem
