import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

const PageContent = styled.div`
  padding-right: 60px;
  h2 {
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 1px;
    font-weight: 400;
  }
  p {
    margin-bottom: 35px;
  }
  @media (max-width: 600px) {
    padding-right: 30px;
  }
`

const AboutPageContainer = () => (
  <StaticQuery
    query={ABOUT_CONTENT}
    render={({ wordpressPage }) => (
      <PageContent>
        {wordpressPage.acf.content.map(content => (
          <div key={content.heading}>
            <h2 dangerouslySetInnerHTML={{ __html: content.heading }} />
            <p
              dangerouslySetInnerHTML={{
                __html: content.body,
              }}
            />
          </div>
        ))}
      </PageContent>
    )}
  />
)

const ABOUT_CONTENT = graphql`
  query AboutPageDetails {
    wordpressPage(wordpress_id: { eq: 56 }) {
      acf {
        content {
          heading
          body
        }
      }
    }
  }
`

export default AboutPageContainer
