import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import TrackVisibility from 'react-on-screen'
import CoursesListItem from './coursesListItem'

const Heading = styled.h1`
  text-transform: uppercase;
  margin: 100px 0 20px 0;
  border-bottom: 2px solid ${props => props.theme.yellow};
  color: ${props => props.theme.yellow};
  padding: 10px 0;
`
const PageContent = styled.div`
  padding-right: 60px;
  @media (max-width: 600px) {
    padding-right: 30px;
  }
`

const AboutCoursesPageContainer = () => (
  <StaticQuery
    query={ABOUT_COURSES_CONTENT}
    render={({ wordpressPage }) => (
      <PageContent>
        <Heading>Online Courses I've done</Heading>
        {wordpressPage.acf.online.map(online => (
          <TrackVisibility partialVisibility key={online.name}>
            <CoursesListItem data={online} />
          </TrackVisibility>
        ))}
      </PageContent>
    )}
  />
)

const ABOUT_COURSES_CONTENT = graphql`
  query AboutCoursesPageDetails {
    wordpressPage(wordpress_id: { eq: 56 }) {
      acf {
        online {
          logo {
            source_url
          }
          name
          courses
          period
        }
      }
    }
  }
`

export default AboutCoursesPageContainer
