import React from 'react'
import posed from 'react-pose'
import styled from 'styled-components'

const ItemStyles = styled.div`
  .logos {
    background: #fff;
    padding: 30px;
    margin: 0 0 40px 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;
    grid-gap: 30px;
    row-gap: 30px;
    img {
      max-width: 110px;
      max-height: 110px;
      margin: 0;
    }
    @media (max-width: 1040px) {
      grid-template-columns: repeat(3, 1fr);
      img {
        max-width: 90px;
        max-height: 90px;
      }
    }
    @media (max-width: 768px) {
      img {
        max-width: 50px;
        max-height: 50px;
      }
    }
  }
`

const Logo = posed.div({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.2,
  },
})
const ItemAnimation = posed.div({
  open: { y: 0, opacity: 1 },
  closed: { y: -50, opacity: 0 },
})

const ToolsListItem = ({ data, isVisible }) => (
  <ItemAnimation pose={isVisible ? 'open' : 'closed'}>
    <ItemStyles>
      <div className="logos">
        {data.map(img => (
          <Logo key={img.sizes.large.title} title={img.sizes.large.title}>
            <img
              src={img.sizes.large.source_url}
              alt={img.sizes.large.title}
              title={img.sizes.large.title}
            />
          </Logo>
        ))}
      </div>
    </ItemStyles>
  </ItemAnimation>
)
export default ToolsListItem
