import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import TrackVisibility from 'react-on-screen'
import EducationListItem from './educationListItem'

const Heading = styled.h1`
  text-transform: uppercase;
  margin: 100px 0 20px 0;
  border-bottom: 2px solid ${props => props.theme.yellow};
  color: ${props => props.theme.yellow};
  padding: 10px 0;
`
const PageContent = styled.div`
  padding-right: 60px;
  @media (max-width: 600px) {
    padding-right: 30px;
  }
`

const AboutEducationPageContainer = () => (
  <StaticQuery
    query={ABOUT_EDUCATION_CONTENT}
    render={({ wordpressPage }) => (
      <PageContent>
        <Heading>Education</Heading>
        {wordpressPage.acf.education.map(education => (
          <TrackVisibility partialVisibility key={education.name}>
            <EducationListItem data={education} />
          </TrackVisibility>
        ))}
      </PageContent>
    )}
  />
)

const ABOUT_EDUCATION_CONTENT = graphql`
  query AboutEducationPageDetails {
    wordpressPage(wordpress_id: { eq: 56 }) {
      acf {
        education {
          name
          field
          period
          logo {
            source_url
          }
        }
      }
    }
  }
`

export default AboutEducationPageContainer
