import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'
import { Link as GLink } from 'gatsby'
import IconAboutMe from '../../../images/icon-about-me.svg'
import IconExperience from '../../../images/icon-experience.svg'
import IconEducation from '../../../images/icon-education.svg'
import IconCourses from '../../../images/icon-courses.svg'
import IconTools from '../../../images/icon-toolbox.svg'

const NavWrapper = styled.nav`
  span {
    display: inline-block;
  }
  img {
    display: none;
  }
  text-transform: uppercase;
  text-align: right;
  position: sticky;
  top: 100px;
  display: table;
  a {
    display: block;
    padding: 10px 40px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
    font-size: 18px;
    transition: all 0.2s ease-in-out;
    &[aria-current='page'],
    &.active {
      border-right: 3px solid ${props => props.theme.yellow};
      color: ${props => props.theme.yellow};
      &:hover {
        color: ${props => props.theme.yellow};
        border-right: 3px solid ${props => props.theme.yellow};
      }
    }
    &:hover {
      border-right: 3px solid rgba(255, 255, 255, 0.8);
      color: #fff;
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    span {
      display: none;
    }
    img {
      display: block;
    }
    position: fixed;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
    bottom: 0;
    left: 0;
    top: auto;
    width: 100vw;
    height: 60px;
    background: #255888;
    z-index: 998;
    a {
      display: grid;
      padding: 5px;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      width: 100%;
      height: 100%;
      text-align: center;
      img {
        margin: 0;
        padding: 0;
      }
      &:hover,
      &.active {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        background: ${props => props.theme.yellow};
        color: ${props => props.theme.dark};
      }
    }
  }
`

const AboutNav = () => {
  const [scrollOffset, setScrollOffset] = useState()
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-10)
    } else {
      setScrollOffset(-90)
    }
  }, [])
  console.log(scrollOffset)
  return (
    <NavWrapper>
      <Link
        to="about"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-153}
        duration={500}
      >
        <img src={IconAboutMe} alt="About Me" />
        <span>About Me</span>
      </Link>
      <Link
        to="experience"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={scrollOffset}
        duration={500}
      >
        <img src={IconExperience} alt="Experience" />
        <span>Experience</span>
      </Link>
      <Link
        to="education"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={scrollOffset}
        duration={500}
      >
        <img src={IconEducation} alt="Education" />
        <span>Education</span>
      </Link>
      <Link
        to="courses"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={scrollOffset}
        duration={500}
      >
        <img src={IconCourses} alt="Courses" />
        <span>Courses</span>
      </Link>
      <GLink
        to="/tools"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={scrollOffset}
        duration={500}
      >
        <img src={IconTools} alt="Tools I use" />
        <span>Tools I use</span>
      </GLink>
    </NavWrapper>
  )
}
export default AboutNav
