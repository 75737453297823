import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import TrackVisibility from 'react-on-screen'
import ToolsListItem from './toolsListItem'

const Heading = styled.h1`
  text-transform: uppercase;
  margin: 100px 0 20px 0;
  border-bottom: 2px solid ${props => props.theme.yellow};
  color: ${props => props.theme.yellow};
  padding: 10px 0;
`
const PageContent = styled.div`
  padding-right: 60px;
  @media (max-width: 600px) {
    padding-right: 30px;
  }
`

const AboutToolsPageContainer = () => (
  <StaticQuery
    query={ABOUT_TOOLS_CONTENT}
    render={({ wordpressPage }) => (
      <PageContent>
        <Heading>Tools &amp; Technology</Heading>
        <p
          dangerouslySetInnerHTML={{
            __html: wordpressPage.acf.software_intro,
          }}
        />
        <TrackVisibility partialVisibility>
          <ToolsListItem data={wordpressPage.acf.software} />
        </TrackVisibility>
      </PageContent>
    )}
  />
)

const ABOUT_TOOLS_CONTENT = graphql`
  query AboutToolsPageDetails {
    wordpressPage(wordpress_id: { eq: 56 }) {
      acf {
        software_intro
        software {
          sizes {
            large {
              source_url
              title
            }
          }
        }
      }
    }
  }
`

export default AboutToolsPageContainer
